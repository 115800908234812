.App {
  text-align: center;
}

@keyframes rotateLogo{
  100% {
    transform: rotateY(360deg);  
  }
}

.App-img {
  height: 30vmin;
  animation-name: rotateLogo;
  animation-duration: 5s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}

.App-text {
  height: 10vmin;
}

.App-m {
  height: 20vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-table-head{
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 45px;
}

.App-table-head1{
  font-family: "Oleo Script", system-ui;
  font-weight: 100;
  font-style: normal;
  font-size: 45px;
}

.App-footer {
  height: 20vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

